import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse, AttachmentMetadata } from '@wpp-open/core'

import { BGAlignmentOptions, Dimensions } from 'types/common/comon'

export enum Templates {
  InnerFirst = 'INNER_FIRST',
  InnerSecond = 'INNER_SECOND',
}

export interface PhaseHeader {
  id: string
  title: MayBeNull<string>
  description: MayBeNull<string>
  groupTitle: MayBeNull<string>
  background?: MayBeNull<AttachmentMetadataResponse>
  positionBackground: BGAlignmentOptions
  logoImage?: MayBeNull<AttachmentMetadataResponse>
  logoDimensions: MayBeNull<Dimensions>
  colors: string[]
  isFullWidth: MayBeNull<boolean>
  height: MayBeNull<number>
}

export interface PhaseColumn {
  id?: string
  icon?: MayBeNull<AttachmentMetadataResponse>
  content?: MayBeNull<string>
  order?: MayBeNull<number>
}

export interface PhaseCard {
  id: string
  background?: MayBeNull<AttachmentMetadataResponse>
  contentImage?: MayBeNull<AttachmentMetadataResponse>
  colors: string[]
  columns: PhaseColumn[]
  isEnabled: MayBeNull<boolean>
  isScrollable: MayBeNull<boolean>
}

export enum PhaseLegendVariant {
  TOOLTIP = 'tooltip',
  SIDEBAR = 'sidebar',
}

export interface PhaseLegendSection {
  id?: string
  title: string
  image?: MayBeNull<AttachmentMetadataResponse>
  colors: string[]
}

export interface PhaseLegend {
  id: string
  isEnabled: MayBeNull<boolean>
  icon?: MayBeNull<AttachmentMetadataResponse>
  colors: string[]
  variant: PhaseLegendVariant
  image?: MayBeNull<AttachmentMetadataResponse>
  title?: MayBeNull<string>
  sections: PhaseLegendSection[]
}

export interface PhaseControls {
  id: string
  colors: string[]
  nextUrl?: MayBeNull<string>
  previousUrl?: MayBeNull<string>
  button?: MayBeNull<{
    id: string
    title?: MayBeNull<string>
    url?: MayBeNull<string>
    isVisible: boolean
  }>
}

export interface PhasePage {
  id: string
  template: Templates
  background?: MayBeNull<AttachmentMetadataResponse>
  colors: string[]
  header: PhaseHeader
  card: PhaseCard
  legend: PhaseLegend
  controls: PhaseControls
}

export interface PayloadPhaseColumn extends Omit<PhaseColumn, 'id' | 'icon'> {
  icon?: MayBeNull<AttachmentMetadata>
}

export interface PayloadLegendSection extends Omit<PhaseLegendSection, 'id' | 'image'> {
  image?: MayBeNull<AttachmentMetadata>
}

export interface CreatePhasePagePayload {
  template: Templates
  background?: MayBeNull<AttachmentMetadata>
  colors?: MayBeNull<string[]>
  header: Partial<
    Omit<PhaseHeader, 'id' | 'background' | 'logoImage' | 'isFullWidth' | 'height' | 'logoDimensions'>
  > & {
    logoImage?: MayBeNull<AttachmentMetadata>
    logoDimensions: Dimensions
    background?: MayBeNull<AttachmentMetadata>
    isFullWidth?: boolean
    height?: number
  }
  card: Partial<Omit<PhaseCard, 'id' | 'background' | 'contentImage' | 'columns' | 'isEnabled' | 'isScrollable'>> & {
    background?: MayBeNull<AttachmentMetadata>
    contentImage?: MayBeNull<AttachmentMetadata>
    columns?: PayloadPhaseColumn[]
    isEnabled?: boolean
    isScrollable?: boolean
  }
  legend?: Partial<
    Omit<PhaseLegend, 'id' | 'image' | 'icon' | 'sections'> & {
      image?: MayBeNull<AttachmentMetadata>
      icon?: MayBeNull<AttachmentMetadata>
      sections?: PayloadLegendSection[]
    }
  >
  controls: Partial<Omit<PhaseControls, 'id' | 'button'>> & {
    button: { title?: MayBeNull<string>; url?: MayBeNull<string>; isVisible?: boolean }
  }
}

export interface EditPhasePagePayload {
  template?: MayBeNull<Templates>
  background?: MayBeNull<AttachmentMetadata>
  colors?: MayBeNull<string[]>
  header?: MayBeNull<Partial<Omit<PhaseHeader, 'id' | 'background' | 'logoImage' | 'logoDimensions'>>> & {
    logoImage?: MayBeNull<AttachmentMetadata>
    logoDimensions?: MayBeNull<Dimensions>
    background?: MayBeNull<AttachmentMetadata>
  }
  card?: MayBeNull<Partial<Omit<PhaseCard, 'id' | 'background' | 'contentImage' | 'columns'>>> & {
    background?: MayBeNull<AttachmentMetadata>
    contentImage?: MayBeNull<AttachmentMetadata>
    columns?: PayloadPhaseColumn[]
  }
  legend?: MayBeNull<
    Partial<Omit<PhaseLegend, 'id' | 'image' | 'icon' | 'sections'>> & {
      image?: MayBeNull<AttachmentMetadata>
      icon?: MayBeNull<AttachmentMetadata>
      sections?: PayloadLegendSection[]
    }
  >
  controls?: MayBeNull<
    Partial<Omit<PhaseControls, 'id' | 'button'>> & {
      button: { title?: MayBeNull<string>; url?: MayBeNull<string>; isVisible?: boolean }
    }
  >
}

export interface FormColumn extends Omit<Partial<PhaseColumn>, 'icon'> {
  icon: [FileItemType?]
}

export interface FormLegendSection extends Omit<Partial<PhaseLegendSection>, 'image'> {
  image: [FileItemType?]
}

export interface PhaseFormConfig
  extends Omit<PhasePage, 'id' | 'background' | 'header' | 'card' | 'legend' | 'controls'> {
  background: [FileItemType?]
  header: Omit<PhaseHeader, 'id' | 'background' | 'logoImage' | 'isFullWidth' | 'height' | 'logoDimensions'> & {
    background: [FileItemType?]
    logoImage: [FileItemType?]
    logoDimensions: Dimensions
    isFullWidth: boolean
    height: number
  }
  card: Omit<PhaseCard, 'id' | 'background' | 'contentImage' | 'columns' | 'isEnabled'> & {
    background: [FileItemType?]
    contentImage: [FileItemType?]
    columns: FormColumn[]
    isEnabled: boolean
    isScrollable: boolean
  }
  legend: Omit<PhaseLegend, 'id' | 'image' | 'icon' | 'sections'> & {
    image: [FileItemType?]
    icon: [FileItemType?]
    sections: FormLegendSection[]
  }
  controls: Omit<PhaseControls, 'id' | 'button'> & {
    button: { title?: MayBeNull<string>; url?: MayBeNull<string>; isVisible?: boolean }
  }
}
