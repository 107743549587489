import { WppAutocompleteCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import {
  WppCard,
  WppAccordion,
  WppTypography,
  WppActionButton,
  WppIconTrash,
  WppTooltip,
  WppIconCopy,
  WppRadio,
} from '@platform-ui-kit/components-library-react'
import { MouseEventHandler, useMemo } from 'react'
import { Controller, useFormContext, FieldArrayWithId } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { FormRichText } from 'components/form/formRichText/FormRichText'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import styles from 'pages/processBuilder/editProcessModal/EditProcessModal.module.scss'
import { templateConfigs } from 'pages/processBuilder/templates'
import { getIsFieldHidden } from 'pages/processBuilder/utils'
import { Templates, ProcessFormConfig, FirstLinearPhaseImageSize } from 'types/process/process'

export const PhaseFields = ({
  phase,
  index,
  isEditMode,
  onRemove,
  onAddOverlay,
  onCopyColors,
}: {
  phase: FieldArrayWithId<ProcessFormConfig, 'phases', 'formId'>
  index: number
  isEditMode: boolean
  onRemove: MouseEventHandler
  onAddOverlay: (e: WppAutocompleteCustomEvent<{}>) => void
  onCopyColors: MouseEventHandler
}) => {
  const { register, control, watch } = useFormContext()
  const formData = watch()
  const { t } = useTranslation()
  const isFieldHidden = getIsFieldHidden(formData.template as Templates)
  const colorsConfig = templateConfigs[formData.template as Templates].colors.phase

  const phaseImageIsSet = useMemo(() => {
    return !!formData?.phases?.[index].image?.length
  }, [formData, index])

  return (
    <WppCard>
      {/* TODO: create an empty phase via api on add click and provide list of ids for group */}
      <input
        type="text"
        value={phase.id || phase.formId}
        {...register(`phases.${index}.localId`, { value: phase.id || phase.formId })}
        hidden
      />

      <WppAccordion withDivider={false} className={styles.phaseAccordion}>
        <WppTypography type="s-strong" slot="header">
          {t('process.edit_modal.phase_number', { number: index + 1 })}
        </WppTypography>
        <WppActionButton className={styles.accordionActionBtn} variant="primary" onClick={onRemove} slot="actions">
          <WppIconTrash slot="icon-start" />
        </WppActionButton>

        <Flex direction="column" gap={10} className={styles.phaseFieldsWrap}>
          <Controller
            render={({ field }) => (
              <FormTextareaInput
                {...field}
                placeholder={t('process.edit_modal.title')}
                hidden={isFieldHidden('phases', 'title')}
                rows={1}
              />
            )}
            name={`phases.${index}.title`}
            control={control}
          />

          <Controller
            render={({ field }) => (
              <FormRichText
                {...field}
                placeholder={t('process.edit_modal.description')}
                hidden={isFieldHidden('phases', 'description')}
              />
            )}
            name={`phases.${index}.description`}
            control={control}
          />

          <Controller
            render={({ field }) => (
              <FormAutocomplete
                {...field}
                showCreateNewElement
                options={[]}
                multiple
                onWppCreateNewOption={onAddOverlay}
                simpleSearch
                defaultValue={[]}
                isDraggable
                placeholder={t('process.edit_modal.overlays')}
                className={styles.phaseAutocomplete}
                hidden={isFieldHidden('phases', 'overlayDescriptions')}
              />
            )}
            name={`phases.${index}.overlayDescriptions`}
            control={control}
          />

          <Flex direction="column" gap={10}>
            <FormFileUpload
              name={`phases.${index}.image`}
              size={1}
              acceptConfig={{
                'image/svg+xml': ['.svg'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
              }}
              maxLabelLength={45}
              maxFiles={1}
              className={styles.fileInput}
              label={t('process.edit_modal.choose_image')}
              hidden={isFieldHidden('phases', 'image')}
            />

            <FormRadioGroup
              name={`phases.${index}.templateOptions.phaseImageSize`}
              hidden={isFieldHidden('phases', 'templateOptions', 'phaseImageSize')}
            >
              <Flex gap={20}>
                <WppRadio
                  value={FirstLinearPhaseImageSize.FULL_WIDTH}
                  name={`phases.${index}.templateOptions.phaseImageSize`}
                  labelConfig={{
                    text: t('process.edit_modal.full_width_image'),
                  }}
                  required
                  disabled={!phaseImageIsSet}
                />
                <WppRadio
                  value={FirstLinearPhaseImageSize.HALF_WIDTH}
                  name={`phases.${index}.templateOptions.phaseImageSize`}
                  labelConfig={{
                    text: t('process.edit_modal.half_width_image_right'),
                  }}
                  required
                  disabled={!phaseImageIsSet}
                />
              </Flex>
            </FormRadioGroup>

            <FormCheckbox
              {...register(`phases.${index}.templateOptions.expandedPhaseImageVisible`)}
              labelConfig={{
                text: t('process.edit_modal.image_visible_expanded_phase'),
              }}
              hidden={isFieldHidden('phases', 'templateOptions', 'expandedPhaseImageVisible')}
              required
              disabled={
                !phaseImageIsSet ||
                formData?.phases?.[index]?.templateOptions?.phaseImageSize === FirstLinearPhaseImageSize.HALF_WIDTH
              }
            />

            <FormCheckbox
              {...register(`phases.${index}.templateOptions.minimizedPhaseImageVisible`)}
              labelConfig={{
                text: t('process.edit_modal.image_visible_minimized_phase'),
              }}
              hidden={isFieldHidden('phases', 'templateOptions', 'minimizedPhaseImageVisible')}
              required
              disabled={
                !phaseImageIsSet ||
                formData?.phases?.[index]?.templateOptions?.phaseImageSize === FirstLinearPhaseImageSize.HALF_WIDTH
              }
            />
          </Flex>

          <Flex gap={8}>
            <FormPalette
              useDefaultColors={!isEditMode}
              {...register(`phases.${index}.colors`)}
              title={t('common.colors')}
              colorsConfig={colorsConfig}
              hidden={isFieldHidden('phases', 'colors')}
              className={styles.colorsInput}
              disableExpanding
              actionsSlot={
                !!index && (
                  <WppTooltip config={{ placement: 'bottom' }} text={t('process.edit_modal.copy_phase_colors_info')}>
                    <WppActionButton variant="primary" onClick={onCopyColors}>
                      <WppIconCopy slot="icon-start" />
                    </WppActionButton>
                  </WppTooltip>
                )
              }
            />
          </Flex>

          <Flex direction="row" justify="between" align="center" gap={15} wrap="wrap">
            <Controller
              render={({ field }) => (
                <FormTextInput
                  {...field}
                  placeholder={t('process.edit_modal.custom_link')}
                  className={styles.inputHalfRow}
                  disabled={formData.phases?.[index].innerPageEnabled}
                  hidden={isFieldHidden('phases', 'url')}
                />
              )}
              name={`phases.${index}.url`}
              control={control}
            />

            <Controller
              render={({ field }) => (
                <FormTextInput
                  {...field}
                  placeholder={t('process.edit_modal.group_title')}
                  className={styles.inputHalfRow}
                  hidden={isFieldHidden('phases', 'groupTitle')}
                />
              )}
              name={`phases.${index}.groupTitle`}
              control={control}
            />

            <FormCheckbox
              {...register(`phases.${index}.innerPageEnabled`)}
              labelConfig={{
                text: t('process.edit_modal.enable_inner_page'),
              }}
              className={styles.inputHalfRow}
              hidden={isFieldHidden('phases', 'innerPageEnabled')}
            />
          </Flex>
        </Flex>
      </WppAccordion>
    </WppCard>
  )
}
